// @ts-nocheck
import User from '@shared/utils/user';

export const initializeBraze = async (userId?: string) => {
  const brazeSDK = await import('@braze/web-sdk');

  if (brazeSDK) {
    brazeSDK.initialize(`${process.env.NEXT_PUBLIC_BRAZE_SDK_KEY}`, {
      baseUrl: `${process.env.NEXT_PUBLIC_BRAZE_SDK}`,
      enableLogging: JSON.parse(`${process.env.NEXT_PUBLIC_BRAZE_ENABLE_LOGGING}`),
    });

    brazeSDK.changeUser(userId || String(User.getId()));

    return brazeSDK;
  }
  return false;
};

export const sendLogCustomEventBraze = async (eventName: string, eventProperties?: {}) => {
  const brazeSDK = await initializeBraze();

  if (brazeSDK) {
    brazeSDK.logCustomEvent(eventName, eventProperties);
  }
};
