import { snowplowButtonClick } from '@analytics/snowplow/events';
import CalloutCard from '@components/callout-card';
import { KeysLocalStorageEnum } from '@local-storage/types';
import { KeyIcon, MailIcon, Toast } from '@pebmed/storybook-react';
import LoginContainer from '@screens/login/components/container';
import { LoginMethodsEnum } from '@screens/login/shared/enums';
import Styles from '@screens/login/styles';
import SessionStorage from '@session-storage/index';
import { TOAST_FIXED_WIDTH } from '@shared/constants/toasts';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export default function LoginScreen() {
  const loginMethod = LoginMethodsEnum.LOGIN;
  const [isError, setIsError] = useState(false);
  const [showCalloutCard, setShowCalloutCard] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const formMethods = useForm({ reValidateMode: 'onBlur' });

  const cameFromContentPageNotLoggedIn = (): void => {
    const url = SessionStorage.get(KeysLocalStorageEnum.SIGNUP_REDIRECT);
    if (url) {
      const originContentPage = url.includes('conteudo');
      trackContentPageNotLoggedIn(originContentPage);
    }
  };

  const trackContentPageNotLoggedIn = (originContentPage: boolean): void => {
    if (originContentPage) {
      setTimeout(() => {
        snowplowButtonClick('content_subscribe', 'content_not_logged_in', null);
      }, 1);
    }
  };

  const handleSignInError = (message: string, errorCode: string) => {
    if (errorCode === 'AUTH_LOGIN_UNAUTHORIZED') {
      setShowCalloutCard(true);
      return;
    }
    setIsError(true);
    setErrorMessage(message);
  };

  useEffect(() => {
    cameFromContentPageNotLoggedIn();
  }, []);

  return (
    <Styles.Content data-testid="login-screen">
      {isError && (
        <Toast error setShowToast={setIsError} width={TOAST_FIXED_WIDTH}>
          {errorMessage}
        </Toast>
      )}
      <Styles.LoginWrapper>
        <Styles.Title>Bem-vindo ao Whitebook</Styles.Title>
        <Styles.SubTitle>Entre com seus dados cadastrados</Styles.SubTitle>
        {showCalloutCard && (
          <Styles.CalloutCardContainer>
            <CalloutCard
              title="Dados incorretos"
              description="Verifique e tente novamente.<br/>Você também pode:"
              buttons={[
                {
                  name: 'Redefinir senha',
                  icon: <KeyIcon />,
                  url: '/esqueci-senha',
                },
                {
                  name: 'Recuperar e-mail',
                  icon: <MailIcon />,
                  url: '/esqueci-email',
                },
              ]}
            />
          </Styles.CalloutCardContainer>
        )}
        <LoginContainer loginMethod={loginMethod} handleSignInError={handleSignInError} formMethods={formMethods} />
      </Styles.LoginWrapper>
    </Styles.Content>
  );
}
