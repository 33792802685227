import styled, { css } from 'styled-components';

import { RadioProps } from '@components/radio/types';
import { FormProps } from '@styles/form/types';
import { device } from '@styles/media';

const FormContainer = styled.form`
  max-width: 330px;
  margin: 0 auto;
  height: calc(100vh - 136px);
  position: relative;
  padding: 0 20px;
  @media ${device.mobile} {
    height: none;
    overflow: hidden;
  }
`;

const Field = styled.div`
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  > [class*='Help'] {
    bottom: 8px;
  }

  > [class*='Eye'] + [class*='Help'] {
    right: 28px;

    [class*='Message'] {
      right: -28px;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  height: 34px;
  border: none;
  outline: none;
  letter-spacing: -0.02em;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  padding: 10px 30px 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.tokens.gray[1200]};
  font-family: 'NunitoSans';
  color: ${({ theme }) => theme.tokens.gray[1900]};

  :disabled {
    background: ${({ theme }) => theme.tokens.gray[100]};
  }

  ${({ disabled, readOnly }: FormProps) =>
    (disabled || readOnly) &&
    css`
      color: ${({ theme }) => theme.tokens.gray[1000]};
    `}

  @media ${device.mobile} {
    font-size: 16px;
  }

  ${({ error, hideError }: FormProps) =>
    error &&
    !hideError &&
    css`
      border-color: ${({ theme }) => theme.tokens.red[550]};
    `}

  ::placeholder {
    color: ${({ theme }) => theme.tokens.gray[800]};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: ${({ theme }) => theme.tokens.gray[1900]} !important;
  }

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & [type='number'] {
    -moz-appearance: textfield;
  }

  & [name='name'] {
    text-transform: capitalize;
  }
`;

const Tip = styled.label`
  width: 100%;
  letter-spacing: -0.02em;
  font-size: 10px;
  line-height: 13px;
  color: ${({ theme }) => theme.tokens.gray[1100]};
  position: absolute;
  left: 0;
  bottom: -18px;
`;

const Eye = styled.span`
  position: absolute;
  top: 30px;
  right: 0;
  width: 15px;
  height: 12px;
  cursor: pointer;
  background: url(/images/ico-eye-off.svg) no-repeat center;

  ${({ display }: FormProps) =>
    display === 'text' &&
    css`
      background: url(/images/ico-eye.svg) no-repeat center;
    `}
`;

const Label = styled.label`
  width: 100%;
  color: ${({ theme }) => theme.tokens.gray[1900]};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: block;

  ${({ disabled, readOnly }: FormProps) =>
    (disabled || readOnly) &&
    css`
      color: ${({ theme }) => theme.tokens.gray[1000]};
    `}

  ${({ error }: FormProps) =>
    error &&
    css`
      color: ${({ theme }) => theme.tokens.red[550]};
    `}
`;

const ForgotPass = styled.p<{ isSignUp?: boolean }>`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.tokens.blue[800]};
  text-decoration: underline;
  cursor: pointer;
  ${({ isSignUp }) =>
    isSignUp &&
    css`
      text-decoration: none;
    `}
`;

const Feedback = styled.p`
  color: ${({ theme }) => theme.tokens.red[550]};
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.02em;
  margin: 5px 0 0;
  min-height: 13px;
`;

const RecaptchaContainer = styled.div`
  transform: scale(1);
  -webkit-transform: scale(1);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
`;

const Dropdown = styled.div`
  width: 100%;
  height: 34px;
  line-height: 34px;
  display: block;
  letter-spacing: -0.02em;
  font-weight: normal;
  font-size: 16px;
  font-family: 'NunitoSans';
  color: ${({ theme }) => theme.tokens.gray[1000]};
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.tokens.gray[1200]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ open }: FormProps) =>
    open &&
    css`
      border-bottom: none;
    `}

  ${({ disabled }: FormProps) =>
    disabled &&
    css`
      cursor: auto;
    `}

  ${({ active, disabled }: FormProps) =>
    active &&
    !disabled &&
    css`
      color: ${({ theme }) => theme.tokens.gray[1900]};
    `}

  ${({ error }: FormProps) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.tokens.red[550]};
    `}

@media ${device.mobile} {
    font-size: 16px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownToggle = styled.span`
  width: 8px;
  height: 5px;
  position: absolute;
  right: 6px;
  top: 5px;
  cursor: pointer;

  ${({ disabled }: FormProps) =>
    disabled &&
    css`
      display: none;
    `}
`;

const DropdownList = styled.div`
  width: 100%;
  height: 200px;
  position: absolute;
  top: 33px;
  left: 0;
  border: 1px solid ${({ theme }) => theme.tokens.gray[400]};
  border-radius: 15px;
  display: none;
  z-index: 50;
  background: ${({ theme }) => theme.tokens.gray[100]};

  ${({ open }: FormProps) =>
    open &&
    css`
      display: block;
    `}
`;

const DropdownOverflow = styled.div`
  width: 100%;
  height: 150px;
  padding-top: 10px;
  background: ${({ theme }) => theme.tokens.gray[100]};
  overflow-y: auto;
  ::-webkit-scrollbar {
    background: transparent;
  }
`;

const DropdownListItem = styled.span`
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding: 0 6px;
  color: ${({ theme }) => theme.tokens.gray[1400]};
  display: block;
  cursor: pointer;
`;

const DropdownItem = styled.span`
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 0 14px;
  border-radius: 50px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 360px;

  &:hover {
    background: ${({ theme }) => theme.tokens.gray[400]};
  }
`;

const DropdownFilter = styled.input`
  width: 100%;
  height: 40px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border: none;
  outline: none;
  letter-spacing: -0.02em;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  padding: 9px 19px 0;
  border-bottom: 1px solid ${({ theme }) => theme.tokens.gray[400]};
  font-family: 'NunitoSans';

  ::placeholder {
    color: ${({ theme }) => theme.tokens.gray[1000]};
  }

  @media ${device.mobile} {
    font-size: 16px;
  }
`;

const CheckboxContainer = styled.label`
  display: block;
  padding-left: 30px;
  padding-right: 20px;
  position: relative;
`;

const Check = styled.span`
  width: 16px;
  height: 16px;
  border: 2px solid ${({ theme }) => theme.tokens.gray[1000]};
  border-radius: 2px;
  display: inline-block;
  cursor: pointer;
  margin-right: 12px;
  position: absolute;
  left: 0;
  top: 0;

  ${({ active }: FormProps) =>
    active &&
    css`
      z-index: 1;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${({ theme }) => theme.tokens.blue[800]};
        display: block;
        margin: 2px auto 0 auto;
      }
    `}
`;

const CheckLabel = styled.span`
  font-weight: normal;
  font-size: 12px;
  color: ${({ theme }) => theme.tokens.gray[1900]};
  margin: 0;
  cursor: pointer;

  ${({ size }: FormProps) =>
    size === 'small' &&
    css`
      font-size: 12px;
    `}

  ${({ disabled }: Partial<RadioProps>) =>
    disabled &&
    css`
      opacity: 50%;
      cursor: default;
    `}

@media ${device.mobile} {
    font-size: 14px;
  }
`;

const RadioContainer = styled.label`
  display: block;
  height: 18px;
  display: flex;
  flex-direction: row;
`;

const Radio = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid ${({ theme }) => theme.tokens.gray[1000]};
  border-radius: 50%;
  position: relative;
  margin-right: 12px;
  margin-top: 2px;
  cursor: pointer;

  ${({ active }: FormProps) =>
    active &&
    css`
      z-index: 1;
      :before {
        content: '';
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 2px;
        right: 2px;
        border-radius: 50%;
        background: ${({ theme }) => theme.tokens.blue[800]};
      }
    `}
  ${({ disabled }: Partial<RadioProps>) =>
    disabled &&
    css`
      opacity: 50%;
      cursor: default;
    `}
`;

const RoundedInput = styled.input`
  font-family: 'NunitoSans';

  width: 270px;
  height: 50px;
  border: 1px solid ${({ theme }) => theme.tokens.gray[400]};
  box-sizing: border-box;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;

  ::placeholder {
    color: ${({ theme }) => theme.tokens.gray[1100]};
  }

  :focus {
    outline: none;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.tokens.gray[1400]};
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.02em;
  margin: 5px 0 0;
  min-height: 13px;
`;

export default {
  FormContainer,
  Input,
  Tip,
  Label,
  Field,
  Eye,
  ForgotPass,
  Feedback,
  RecaptchaContainer,
  Dropdown,
  DropdownToggle,
  DropdownContainer,
  DropdownList,
  DropdownFilter,
  DropdownListItem,
  DropdownOverflow,
  DropdownItem,
  CheckboxContainer,
  Check,
  CheckLabel,
  RadioContainer,
  Radio,
  RoundedInput,
  Description,
};
