import { PageHeadTagTypes } from '@head-tags/page-tags/types';
import { PageHeaderTypes, PageLayoutTypes } from '@layout/types';
import LoginScreen from '@screens/login';

export default LoginScreen;

export async function getStaticProps() {
  return {
    props: {
      layout: PageLayoutTypes.FreeContent,
      header: PageHeaderTypes.ForgotPass,
      tags: PageHeadTagTypes.Login,
    },
  };
}
