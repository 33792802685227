import { useEffect, useState } from 'react';

import Style from '@components/tooltip/style';
import { TooltipProps } from '@components/tooltip/types';

export default function Tooltip({ message, align }: TooltipProps) {
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState(undefined);

  const handleHide = () => {
    const hide = (e) => {
      const isIcon = e.target?.attributes?.src?.value === '/images/ico-help.svg';

      if (isIcon) return;

      setShow(false);
    };

    document.querySelector('body').addEventListener('click', hide);
  };

  const handleActive = () => {
    setShow((current) => !current);
  };

  const handleDismiss = () => {
    if (!show) return;

    if (timer) clearTimeout(timer);

    const counter = setTimeout(() => {
      setShow(false);
    }, 6000);

    setTimer(counter);
  };

  useEffect(() => {
    handleHide();
  }, []);

  useEffect(() => {
    handleDismiss();
  }, [show]);

  return (
    <Style.Help align={align} data-testid="help" role="tooltip">
      <Style.Icon src="/images/ico-help.svg" onClick={handleActive} data-testid="icon" />
      <Style.Message display={show ? 1 : 0} align={align}>
        {message}
      </Style.Message>
    </Style.Help>
  );
}
